import React from "react"
import { useNotificationModalContext } from "./provider"
import { Box } from "@chakra-ui/react"

function Overlay(props) {
  const { children } = props

  const notificationModal = useNotificationModalContext()

  return (
    <Box
      onClick={notificationModal.handleOverlayClick}
      position="fixed"
      display={notificationModal.open ? "flex" : "none"}
      justifyContent={"flex-end"}
      border={4}
      bottom={0}
      top={"1"}
      left={0}
      right= "0"
      zIndex={9999}
    >
      {children}
    </Box>
  )
}

export default Overlay
