import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import User from "../helpers/current-user";
import Const from "../utils/constant";
const Content = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const isFormPage = pathname?.includes('/my-audit/checklist') ; 
  const showSidebar = !isFormPage;
  const { children, SideMenu, Header, Footer } = props;
  const isMMDSupervisor = User.getRole() === Const.Role.MMD_SUPERVISOR;
  return (
    <Box position="absolute" bg="white.50" minHeight="100vh" h="100vh">
      <Box mb={"65px"}>{Header && <Header {...children.props} />}</Box>
      <Box display={"flex"}>
        {showSidebar && SideMenu && (
          <Box w="261px">
            {" "}
            <SideMenu {...children.props} />
          </Box>
        )}
        <Text position={"absolute"} fontWeight={800} fontSize={"18px"} color="lblack" left={"24rem"} top={"7.5rem"}>
          { isMMDSupervisor? "":User.getdept()}
        </Text>
        <Box mx={isFormPage ? 0 : "19px"} flex={1}>
          {children}
        </Box>
      </Box>
      {/* <LoaderOverlay active={false} /> */}
      {Footer && <Footer {...children.props} />}
    </Box>
  );
};

export default Content;
