const constants = {
	API_URL: process.env.REACT_APP_API_URL,
	NOTIFICATION_TYPE: {
		SUCCESS: "success",
		ERROR: "error",
		INFO: "info",
		WARNING: "warning",
		PROGRESS: "progress",
		CLOSE: "close",
		CONFIRM: "confirm",
	  },
	  LIMIT:200,
	  Role: {
		ADMIN: "ADMIN",
		AUDITOR: "AUDITOR",
		COMPETENT_AUTHORITY: "COMPETENT_AUTHORITY",
		RO: "RO"
	  },
	  AUDITOR_ROLES:[
		{ label: "Lead Auditor", role_in_audit: "lead-auditor" },
		{ label: "Auditor", role_in_audit: "auditor" },
		{ label: "Trainee Auditor", role_in_audit: "trainee-auditor" },

	  ],

	  RO_FORM_SHORT_CODES: {
		RC: "RC",
		RA: "RA",
		RD: "RD",
		RP: "RP"
	  },
	  AUDITOR_FORM_SHORT_CODES: {
		AC: "AC",
		AR: "AR",
	  }, 
};

export default constants;
