import * as React from "react";
const InfoIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <circle cx={8} cy={8} r={6.5} stroke="#004676" />
    <path
      fill="#004676"
      stroke="#004676"
      strokeWidth={0.2}
      d="M7.136 7v.5h.5c.5 0 0 1.5-.5 3s.5 1.5 1 1.5c.672 0 1-.5 1-.5V11c-.333.167-1 .4-1 0s.667-3.167 1-4.5l-2 .5Z"
    />
    <circle cx={8.036} cy={4.9} r={0.9} fill="#004676" />
  </svg>
);
export default InfoIcon;
