import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import User from "../helpers/current-user";
import {
  Box,
  Button,
  Flex,
  Link,
  List,
  ListItem,
  Text,
  Avatar
} from "@chakra-ui/react";
import OigLogo from "../assets/oig-logo";
import { AiFillCaretDown } from "react-icons/ai";
import { MdLogout, MdHelpOutline } from "react-icons/md";
import { FaUser, FaKey } from "react-icons/fa";
import { customColors } from "../helpers/theme";
import { useDisclosure } from "@chakra-ui/hooks";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import Const from "../utils/constant";

const Header = () => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const headerTitle = () => {
    let title = "";
    return title;
  };
  const Menus = [
    {
      title: "Change Password",
      icon: <FaKey />,
      onClick: () => {
        setOpen(false);
        navigate("/update-password");
      },
    },
    {
      title: "Logout",
      icon: <MdLogout />,
      onClick: () => {
        setOpen(false);
        User.logout();
        navigate("/");
      },
    },
  ];

  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [videoUrl, setVideoUrl] = useState("");
  const roles = [
    { role: 'is_admin', url: 'https://roaudit-help-video.s3.ap-south-1.amazonaws.com/RO+Assessment+Help+Video+-+For+Super+Admin+user.mp4' },
    { role: 'is_competent_authority', url: 'https://roaudit-help-video.s3.ap-south-1.amazonaws.com/RO+Assessment+Help+Video+for+Competent+Authority+Users.mp4' },
    { role: 'is_auditor', url: 'https://roaudit-help-video.s3.ap-south-1.amazonaws.com/RO+Assessment+Help+Video+-+For+Auditors.mp4' }
  ];
  useEffect(() => {
    if (User?.loggedin()) {
      setIsLoggedIn(true);
    }
    if (User) {
      const matchingRole = roles.find(r => User.get()[r.role]);
      if (matchingRole) {
        setVideoUrl(matchingRole.url);
      }
    }
  }, []);


  return (
    isLoggedIn && (
      <>
        <Box
          position={"fixed"}
          top={"0"}
          left={"0"}
          zIndex={9}
          w="100%"
          mr="20px"
          bg={"white"}
        >
          <Flex
            w="full"
            h="65px"
            fontFamily="roboto"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            boxShadow="0 4px 4px 0 rgba(0, 0, 0, 0.08)"
            as="nav"
          >
            <Box py="7px" px="19px" transform={"scale(0.8)"}>
              <Link href="/">
                <OigLogo />
              </Link>
            </Box>

            <Box
              position={"absolute"}
              mx="auto"
              w="max-content"
              left={0}
              right={0}
            >
              <Text
                fontFamily={"pri"}
                fontSize="18px"
                color="lblack"
                fontWeight="700"
              >
                {headerTitle()}
              </Text>
            </Box>

            <Box mr="25px">
              <Flex alignItems="baseline">
               { !User.get().is_ro && <Box pt={4}>
                  <Link to="/help">
                    <Button type="button" variant="link" color="primary" className="flex items-center" onClick={onOpen} pt={2} >
                      <MdHelpOutline size={"16px"} style={{ color: "#2F68A1" }} color="" />
                      <Text
                        fontSize="14px"
                        fontWeight="normal"
                        mx="3px"
                        color="secondary_theme"
                      >
                        Help
                      </Text>
                    </Button>
                  </Link>
                </Box>}
                <Button backgroundColor="white" _hover={"none"}>
                  <Flex
                    alignItems="center"
                    cursor={"pointer"}
                    onClick={() => setOpen(!open)}
                  >
                    <Avatar name={User?.getname()} size={'md'} mb={1} />
                    <Text
                      fontSize="14px"
                      fontWeight="normal"
                      mx="4px"
                      color="secondary_theme"
                      userSelect={"none"}
                    >
                      {User?.getname() || "Super Admin"}
                    </Text>
                  </Flex>
                  <Box
                    onClick={() => setOpen(!open)}
                    cursor={"pointer"}
                    pb="4px"
                    color="secondary_theme"
                  >
                    <AiFillCaretDown />
                  </Box>
                </Button>
                <Box>
                  {open && (
                    <Box
                      position="absolute"
                      bg="white"
                      boxShadow="lg"
                      right="5"
                      top="55px"
                      zIndex={19}
                      shadow={"xl"}
                      borderRadius={"6px"}
                      overflow={"hidden"}
                    >
                      <Box bg="gray_5" w="100%" h="100%">
                        <List>
                          {Menus.map((item, i) => (
                            <ListItem
                              onClick={item.onClick}
                              p={2}
                              px={4}
                              fontFamily="sec"
                              fontSize="14px"
                              cursor="pointer"
                              _hover={{ backgroundColor: customColors.gray[200] }}
                              key={i}
                              display={"flex"}
                              alignItems={"center"}
                            >
                              {item.icon}
                              <Text ml="8px" fontWeight={500}>
                                {item.title}
                              </Text>
                            </ListItem>
                          ))}
                        </List>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Flex>
            </Box>
          </Flex>
          <Modal isOpen={isOpen} onClose={onClose} size={"6xl"} >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Help Video</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <video controls autoPlay>
                  <source src={videoUrl} type="video/mp4" />
                </video>
              </ModalBody>
              <ModalFooter>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </Box>
        {open && (
          <Box
            position={"absolute"}
            w="100%"
            h="100%"
            onClick={() => setOpen(false)}
            zIndex={8}
          ></Box>
        )}
      </>
    )
  );
};

export default Header;
