function IconWarning(props) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx={24} cy={24} r={24} fill="#EFC943" />
      <path
        d="M24.2857 27.5625C24.0314 27.5625 23.7828 27.634 23.5714 27.7679C23.36 27.9018 23.1952 28.0922 23.0979 28.3149C23.0006 28.5376 22.9751 28.7826 23.0247 29.019C23.0743 29.2554 23.1968 29.4726 23.3766 29.643C23.5564 29.8135 23.7855 29.9296 24.0349 29.9766C24.2843 30.0236 24.5428 29.9995 24.7777 29.9072C25.0127 29.815 25.2135 29.6588 25.3547 29.4584C25.496 29.2579 25.5714 29.0223 25.5714 28.7812C25.5714 28.458 25.436 28.148 25.1949 27.9195C24.9537 27.6909 24.6267 27.5625 24.2857 27.5625ZM23.4286 18H25.1429V26.125H23.4286V18Z"
        fill="white"
      />
      <path
        d="M24.8598 11.4064L36.9345 31.7394C37.3304 32.406 36.85 33.25 36.0747 33.25H11.9253C11.15 33.25 10.6696 32.406 11.0655 31.7394L23.1402 11.4064C23.5277 10.7538 24.4723 10.7538 24.8598 11.4064Z"
        stroke="white"
        strokeWidth={2}
      />
    </svg>
  );
}

export default IconWarning;
