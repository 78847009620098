import React, { Suspense, lazy } from "react";

export default function withLoadable(lazyImport, config = {}) {
  const { suspenseFallback = null, } = config;

  const Component = lazy(lazyImport);
  const suspenseProps = {};

  if (suspenseFallback) {
    suspenseProps.fallback = suspenseFallback;
  }

  return function Loadable(props) {
    return (
        <Suspense {...suspenseProps}>
          <Component {...props} />
        </Suspense>
    );
  };
}
