import React from "react";
import { Box, Text, Link } from "@chakra-ui/react";
import { useLocation, NavLink as RouterLink } from "react-router-dom";
import styled from "@emotion/styled";
import sidemenuRoutes from "./sidemenuRoutes";
import DGSPermittedSection from "../components/dgs-permitted-section"; 
const NavLink = styled(Link)`
  z-index: 1;
`;

const SideMenu = () => {
  const [navItems] = React.useState(sidemenuRoutes || []);
  const location = useLocation();
  const { pathname } = location;  
  return (
    <Box
      backgroundColor="secondary_theme"
      w="261px"
      boxShadow="lg"
      h="100vh"
      position="fixed"
    >
      <Box d="flex" justifyContent="left"> 
      </Box>
      <Box px={2} py={3} h="100%" overflowX="hidden">
        {navItems.map((item, i) => (
          <DGSPermittedSection
            key={i}
          >
            <NavLink
              display="flex"
              alignItems="center"
              mb={1}
              as={RouterLink}
              style={{ textDecoration: "none" }}
              to={item.to}
              exact={true}
            >
              <Box
                display="flex"
                alignItems="center"
                px={2}
                color={pathname !== item.to ? "theme_white" : "secondary_theme"}
                backgroundColor={pathname !== item.to ? "secondary_theme" : "theme_white"}
                w={"100%"}
                borderRadius={"md"}
              >
                {pathname !== item.to ? item.icon() : item.selected_icon()}
                <Text
                  fontSize="14px"
                  fontWeight={400}
                  fontFamily={"sec"}
                  pl={"20px"}
                  py={"7px"}
                >
                  {item.name}
                </Text>
              </Box>
            </NavLink>
          </DGSPermittedSection>
        ))}
      </Box>
    </Box>
  );
};

export default SideMenu;
