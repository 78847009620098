import React from 'react';
import { useConfirmationModal } from './provider';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import DgsButton from '../dgs-button';
import { Formik } from 'formik';
import { IoCloseCircleOutline } from 'react-icons/io5';
import is from '../../utils/is';

const ConfirmationModal = () => {
  const {
    open,
    close,
    body,
    heading,
    initialValue,
    validationSchema,
    onConfirm,
    cancelText, confirmText, 
  } = useConfirmationModal();
  if (!open) {
    return null;
  } 
  return (
    <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          position={"absolute"}
          w="100%"
          h="100%"
          left="0"
          top="0"
          backgroundColor="#0000005e"
          zIndex={9999}
        >
          <Box
            maxH={'80vh'}
            maxW={'90vh'}
            padding={6}
            mb="10%"
            borderRadius={"8"}
            boxShadow={"2xl"}
            background={"white"}
            display={"flex"}
            flexDir={"column"}
          >
            <Button
              onClick={close}
              variant={"filled"}
              alignSelf={"flex-end"}
              p="0"
              m="2"
            >
              <IoCloseCircleOutline size={24} color="black" />
            </Button>
            <Box
              px="4"
              display={"flex"}
              flexDir={"column"}
              alignItems={"center"} 
            >
              <Text
                textAlign={"center"}  
                color={"lblack"}
              >
                {heading}
              </Text>
              <Formik
                enableReinitialize
                validateOnBlur={false}
                validateOnChange={true}
                onSubmit={onConfirm}
                initialValues={initialValue}
                validationSchema={validationSchema}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    <Flex
                      alignItems={"center"}
                      justifyContent={"center"}
                      pt="2"
                    >
                       {is.function(body) ? body(formik) : body}
                    </Flex>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      pt={4}
                      gap={4}                      
                    >
                      <DgsButton
                        outline
                        py={4} px="6"
                        onClick={close}
                      >
                        {cancelText}
                      </DgsButton>
                      <DgsButton type="submit" py={4} px="6" >
                        {confirmText}
                      </DgsButton>
                    </Box>
                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </Box>
  );
};

export default ConfirmationModal;
