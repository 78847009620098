import { lazy } from "react";

export const UNAUTHENTICATED_PAGE_NAMES = {
  HOME: "HOME",
  LOGIN: "LOGIN",
  UPDATE_PASSWORD: "UPDATE_PASSWORD" 
};

export const unauthenticatedRoutes = [
  {
    id: 1,
    pageName: UNAUTHENTICATED_PAGE_NAMES.HOME,
    exact: true,
    path: "/",
    asyncComponent: lazy(() => import("../pages/login-page")),
  },
  {
    id: 2,
    pageName: UNAUTHENTICATED_PAGE_NAMES.LOGIN,
    exact: true,
    path: "/login",
    asyncComponent: lazy(() => import("../pages/login-page")),
  },
  {
    id: 3,
    pageName: UNAUTHENTICATED_PAGE_NAMES.UPDATE_PASSWORD,
    exact: true,
    path: "/update-password",
    asyncComponent: lazy(() => import("../pages/update-password")),
  },
];
