import { Button } from '@chakra-ui/react'
import React from 'react'

function DgsButton({children, outline,  ...restProps}) {
  return (
    <Button
    borderColor={outline && "primary_theme"}
    borderWidth={outline &&"1px"}
    color={outline ? "primary_theme" : "theme_white"}
    borderRadius="4"
    cursor="pointer" 
    variant={outline ? "outline" : "filled"}
    backgroundColor={outline ?"white" :"primary_theme"}
    colorScheme="teal"
    fontWeight={'normal'}
    _hover={"none"}
    {...restProps}
    >
    {children}
  </Button>
  )
}

export default DgsButton