import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import routes from "./routes/routes";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./helpers/theme";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import { UserProvider } from "./utils/user-context";
import NotificationModal from "./components/notification-modal"
import { NotificationModalProvider } from "./components/notification-modal/provider"
import "./custom-style.css"
import { AxiosInterceptorProvider } from "./apis";
import { ErrorBoundary } from "react-error-boundary";
import ErrorBoundaryFallback from "./components/error-boundary-fallback";
import { ConfirmationModalProvider } from "./components/confirmation-modal-with-input/provider";
import ConfirmationModal from "./components/confirmation-modal-with-input";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <> 
  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>   
   <Router>
      <NotificationModalProvider>
        <ConfirmationModalProvider>
        <NotificationModal />
        <AxiosInterceptorProvider>
          <UserProvider>
            <ChakraProvider theme={theme}>
                <Suspense fallback={<div>Loading...</div>}>{routes}</Suspense>
                <ConfirmationModal />
            </ChakraProvider>
          </UserProvider>
        </AxiosInterceptorProvider>
        </ConfirmationModalProvider>
      </NotificationModalProvider>
    </Router>  
    </ErrorBoundary>
  </>
);
