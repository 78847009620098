import React from "react";
import {
  UNAUTHENTICATED_PAGE_NAMES,
  unauthenticatedRoutes,
} from "./Unauthenticated";
import { authenticatedRoutes, checkHasAcess } from "./Authenticated";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "../layout/content";
import SideMenu from "../layout/sidemenu";
import Header from "../layout/header";
import { useUser } from "../utils/user-context";
import User from "../helpers/current-user";
import NotFound from "../pages/not-found";


const PrivateRoute = ({ Component, access, elementProps }) => {
  const { userRole } = useUser();
  if (checkHasAcess({ access }, userRole)) {
    return (
      <Layout SideMenu={SideMenu} Header={Header}>
        <Component  {...elementProps} />
      </Layout>
    );
  } else {
    return (
      <Layout SideMenu={SideMenu} Header={Header}>
        <Component  {...elementProps} />
      </Layout>
    );
  }
};

const LoginRoute = ({ Component }) => {
  if (User.havingTempPassword()) {
    return <Navigate to="/update-password" />;
  } else if (User.loggedin()) {
    if (User.get().is_admin) {
      return <Navigate to="/manage-users" />;
    }
    if (User.get().is_ro) {
      return <Navigate to="/my-audit" />;
    }
    if (User.get().is_competent_authority) { 
      return <Navigate to="/ro-audit" />;
    }
    if (User.get().is_auditor) {
      return <Navigate to="/my-audits" />;
    }
  } else {
    return <Component />;
  }
};

const PublicRoute = ({ Component }) => {
  return <Component />;
};
const routes = (
  <React.Fragment>
    <Routes>
      {unauthenticatedRoutes.map(
        (route) =>
          route.pageName === UNAUTHENTICATED_PAGE_NAMES.UPDATE_PASSWORD && (
            <Route
              key={route.id}
              exact={route.exact}
              path={route.path}
              element={<PublicRoute Component={route.asyncComponent} />}
            />
          )
      )}
      {unauthenticatedRoutes.map(
        (route) => (
          <Route
            key={route.id}
            exact={route.exact}
            path={route.path}
            element={<LoginRoute Component={route.asyncComponent} />}
          />
        )
      )}
      {authenticatedRoutes.map((route) => (
        <Route
          key={route.id}
          exact={route.exact}
          path={route.path}
          element={
            <PrivateRoute
              elementProps={route.elementProps}
              access={route.access}
              Component={route.asyncComponent}
            />
          }
        />
      ))}
      <Route
        path="*"
        element={
          <Layout Header={Header}>
            <NotFound />
          </Layout>
        }
      />
    </Routes>
  </React.Fragment>
);
export default routes;
