import cookie from "js-cookie";
import Const from "../helpers/constants";
class User {
  set = (data) => {
    const { token, user_info } = data;
    cookie.set("token", token);
    if (user_info?.is_admin) {
      user_info.role = Const.Role.ADMIN
    }
    else if (user_info?.is_auditor && user_info?.is_competent_authority) {
      user_info.role = [Const.Role.AUDITOR, Const.Role.COMPETENT_AUTHORITY]
    }
    else if (user_info?.is_auditor) {
      user_info.role = Const.Role.AUDITOR
    }else if (user_info?.is_competent_authority) {
      user_info.role = Const.Role.COMPETENT_AUTHORITY
    }else if (user_info?.is_ro) {
      user_info.role = Const.Role.RO
    }
    localStorage.setItem("userInfo", JSON.stringify(user_info));
  };

  get = () => {
    const user = localStorage.getItem("userInfo");
    if (user != null) {
      return JSON.parse(user);
    } else {
      return {};
    }
  };

  getname = () => {
    const user = localStorage.getItem("userInfo");
    if (user != null) {
      const user_details = JSON.parse(user);
      return `${user_details.name || user_details.organization_name}`;
    } else {
      return "";
    }
  };

  getdept = () => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    if (user?.is_supervisor) {
      return `${user.location}`;
    } else return "";
  };

  havingTempPassword = () => {
    const user = localStorage.getItem("userInfo");
    if (user != null) {
      return JSON.parse(user)?.force_pwd_change;
    } else {
      return false;
    }
  };

  getRole = () => {
    const user = localStorage.getItem("userInfo");
    if (user != null) {
      return JSON.parse(user)?.role;
  };
}

  update = (data) => {
    localStorage.setItem("userInfo", JSON.stringify(data));
  };

  token = () => {
    return cookie.get("token");
  };

  loggedin() {
    return this.token() != null;
  }

  logout() {
    cookie.remove("token");
    window.localStorage.clear();
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new User();
