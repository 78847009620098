import Const from "../helpers/constants";
import User from "../helpers/current-user";
import withLoadable from "../components/with-loadable";
import is from "../utils/is";

export const AUTHENTICATED_PAGE_NAMES = { 
  MANAGE_USERS: "MANAGE_USERS",
  MANAGE_RO: "MANAGE_RO",
  UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
  CA_AUDITOR_DOCUMENT: "CA_AUDITOR_DOCUMENT",
  DASHBOARD: "dashboard",
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  RO_AUDIT: "RO_AUDIT",
  RO_FORM_CHECKLIST:"RO_FORM_CHECKLIST",
  RO_AUDIT_REPORT_RC:"RO_AUDIT_REPORT_RC",
  RO_CHECKLIST_RP_UPDATE: "RO_CHECKLIST_RP_UPDATE",
  RO_CHECKLIST_RP: "RO_CHECKLIST_RP",
  RO_CHECKLIST_RD_UPDATE: "RO_CHECKLIST_RD_UPDATE",
  AO_CHECKLIST_AC: "AO_CHECKLIST_AC",
  AUDITOR_AUDITS:"AUDITOR_AUDITS",
  AUDITOR_FORM_CHECKLIST:"AUDITOR_FORM_CHECKLIST",
  AUDITOR_NC_FINDING:"AUDITOR_NC_FINDING",
  AUDITOR_NC_AUDITS:"AUDITOR_NC_AUDITS",
  AO_REPORT_AR: "AO_REPORT_AR",
  FINDINGS_REPORT_LIST: "FINDINGS_REPORT_LIST",
};

const adminRoutes = [
  {
    id: 1,
    pageName: AUTHENTICATED_PAGE_NAMES.MANAGE_USERS,
    exact: true,
    path: "/manage-users",
    asyncComponent: withLoadable(() => import("../pages/manage-users-page")),
    access: [Const.Role.ADMIN],
  },
  {
    id: 2,
    pageName: AUTHENTICATED_PAGE_NAMES.MANAGE_RO,
    exact: true,
    path: "/manage-ro",
    asyncComponent: withLoadable(() => import("../pages/manage-users-page")),
    elementProps: { pageFor: [Const.Role.RO] },
    access: [Const.Role.ADMIN],
  },
  {
    id: 3,
    pageName: AUTHENTICATED_PAGE_NAMES.UPLOAD_DOCUMENT,
    exact: true,
    path: "/upload-documents",
    asyncComponent: withLoadable(() => import("../pages/upload-document")),
    access: [Const.Role.ADMIN, Const.Role.RO, Const.Role.AUDITOR, Const.Role.COMPETENT_AUTHORITY],
  },
  {
    id: 4,
    pageName: AUTHENTICATED_PAGE_NAMES.DASHBOARD,
    exact: true,
    path: "/home",
    asyncComponent: withLoadable(() => import("../pages/dashboard-page")),
    access: [Const.Role.AUDITOR, Const.Role.RO, Const.Role.COMPETENT_AUTHORITY],
  },
  {
    id: 5,
    pageName: AUTHENTICATED_PAGE_NAMES.UPDATE_PASSWORD,
    exact: true,
    path: "/update-password",
    asyncComponent: withLoadable(() => import("../pages/update-password")),
    access: [Const.Role.AUDITOR, Const.Role.RO, Const.Role.COMPETENT_AUTHORITY],
  },
  {
    id: 5,
    pageName: AUTHENTICATED_PAGE_NAMES.RO_AUDIT,
    exact: true,
    path: "/ro-audit",
    asyncComponent: withLoadable(() => import("../pages/ro-audit-page")),
    access: [Const.Role.COMPETENT_AUTHORITY, Const.Role.AUDITOR],
  },
  {
    id: 6,
    pageName: AUTHENTICATED_PAGE_NAMES.RO_FORM_CHECKLIST,
    exact: true,
    path: "/my-audit",
    asyncComponent: withLoadable(() => import("../pages/ro-form-checklist-page")),
    access: [Const.Role.RO, Const.Role.AUDITOR],
  },
  {
    id: 5,
    pageName: AUTHENTICATED_PAGE_NAMES.RO_AUDIT_REPORT_RC,
    exact: true, 
    path: "/my-audit/checklist/rc/:id?",
    asyncComponent: withLoadable(() => import("../pages/ro-audit-report-rc")),
    access: [Const.Role.RO, Const.Role.AUDITOR],
  },
  {
    id: 7,
    pageName: AUTHENTICATED_PAGE_NAMES.AUDIT_CHECKLIST,
    exact: true,
    path: "/my-audit/checklist/ra/:id?",
    asyncComponent: withLoadable(() => import("../pages/ro-checklist-ra")),
    access: [Const.Role.RO, Const.Role.AUDITOR],
  },
  {
    id: 7,
    pageName: AUTHENTICATED_PAGE_NAMES.RO_CHECKLIST_RP_UPDATE,
    exact: true,
    path: "/my-audit/checklist/rp/:id",
    asyncComponent: withLoadable(() => import("../pages/ro-checklist-rp")),
    access: [Const.Role.RO, Const.Role.AUDITOR],
  },
  {
    id: 7,
    pageName: AUTHENTICATED_PAGE_NAMES.RO_CHECKLIST_RP,
    exact: true,
    path: "/my-audit/checklist/rp/",
    asyncComponent: withLoadable(() => import("../pages/ro-checklist-rp")),
    access: [Const.Role.RO, Const.Role.AUDITOR],
  },
  {
    id: 7,
    pageName: AUTHENTICATED_PAGE_NAMES.RO_CHECKLIST_RD_UPDATE,
    exact: true,
    path: "/my-audit/checklist/rd/:id?",
    asyncComponent: withLoadable(() => import("../pages/ro-checklist-rd")),
    access: [Const.Role.RO,Const.Role.AUDITOR],
  },
  {
    id: 8,
    pageName: AUTHENTICATED_PAGE_NAMES.AO_CHECKLIST_AC,
    exact: true,
    path: "my-audit/checklist/:auditorRequestId/ac/:id?",
    asyncComponent: withLoadable(() => import("../pages/ao-checklist-ac")),
    access: [Const.Role.AUDITOR],
  },
  {
    id: 9,
    pageName: AUTHENTICATED_PAGE_NAMES.AUDITOR_AUDITS,
    exact: true,
    path: "/my-audits",
    asyncComponent: withLoadable(() => import("../pages/auditor-audits")),
    access: [Const.Role.AUDITOR],
  },
  {
    id: 6,
    pageName: AUTHENTICATED_PAGE_NAMES.AUDITOR_FORM_CHECKLIST,
    exact: true,
    path: "/my-audit/list/:id?",
    asyncComponent: withLoadable(() => import("../pages/ro-form-checklist-page")),
    access: [Const.Role.AUDITOR],
  },
  {
    id: 7,
    pageName: AUTHENTICATED_PAGE_NAMES.AUDITOR_NC_FINDING,
    exact: true,
    path: "/nc-finding/:ro_id",
    asyncComponent: withLoadable(() => import("../pages/ao-nc-finding")),
    access: [Const.Role.AUDITOR, Const.Role.RO],
    elementProps: { pageFor: [Const.Role.AUDITOR, Const.Role.RO] },
  },  
  {
    id: 8,
    pageName: AUTHENTICATED_PAGE_NAMES.AUDITOR_NC_AUDITS,
    exact: true,
    path: "/nc-finding",
    asyncComponent: withLoadable(() => import("../pages/finding-report-list")),
    access: [Const.Role.AUDITOR],
    elementProps: { pageFor: [AUTHENTICATED_PAGE_NAMES.AUDITOR_NC_AUDITS] },
  },
  {
    id: 9,
    pageName: AUTHENTICATED_PAGE_NAMES.AO_REPORT_AR,
    exact: true,
    path: "my-audit/checklist/:auditorRequestId/ar/:id?",
    asyncComponent: withLoadable(() => import("../pages/ao-report-ar")),
    access: [Const.Role.AUDITOR],
  }, 
  {
    id: 10,
    pageName: AUTHENTICATED_PAGE_NAMES.FINDINGS_REPORT_LIST,
    exact: true,
    path: "/findings-report",
    asyncComponent: withLoadable(() => import("../pages/finding-report-list")),
    access: [Const.Role.COMPETENT_AUTHORITY],
  },  
  {
    id: 11,
    pageName: AUTHENTICATED_PAGE_NAMES.CA_AUDITOR_DOCUMENT,
    exact: true,
    path: "/ca-auditor-documents",
    asyncComponent: withLoadable(() => import("../pages/upload-document")),
    access: [Const.Role.ADMIN,  Const.Role.AUDITOR, Const.Role.COMPETENT_AUTHORITY],
  },
];


const portRoutes = [];

const userRoles =User?.get().role;

export const checkHasAcess=(menuItem, userRoles)=>{
if (is.array(userRoles)) {
 return menuItem?.access?.some(element=> userRoles.includes(element)); 
} else {
  return menuItem.access?.includes(userRoles)
}
}

export const authenticatedRoutes = [
  ...adminRoutes,
  ...portRoutes,
].filter((menuItem)=> checkHasAcess(menuItem, userRoles));
