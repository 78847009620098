import React from "react"
import cx from "../../utils/cx"
import { useNotificationModalContext } from "./provider"
import NotificationIcon from "./notification-icon"
import {
  Box,
  Button,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
function Modal(props) {
  const { children } = props

  const notificationModal = useNotificationModalContext()

  return (
    <Box
      onClick={e => e.stopPropagation()}
      display={"flex"}
      boxShadow="0px -4px 4px -2px rgba(0, 0, 0, 0.1),
      0px 4px 2px -2px rgba(0, 0, 0, 0.06)"
      borderTopRightRadius={"100px"}
      borderTopLeftRadius={"100px"}
      flexDirection={"column"}
      width={"100vw"}
      justifyContent={"center"}
      alignItems={"center"}
      justifyItems={"center"}
      bgColor={"#ffffff"}
      marginTop={"auto"}
      minHeight={"256px"}
      translateY={notificationModal.open && 0}
    >
      <Box >
        {children}
      </Box>
    </Box>
  )
}

function ModalBody(props) {
  const {
    type,
    title,
    heading,
    body,
    onConfirm,
    initialValue,
    validationSchema,
    classNames = {},
  } = useNotificationModalContext()
  return (
    <div className="notification-modal_body">
      <NotificationIcon type={type} />
      {title ? <Text fontSize={"20px"} fontWeight={'semibold'} >{title}</Text> : null}
      {heading ? (
        <Text
          className={cx(
            "notification-modal_heading",
            classNames?.heading,
            title && "with-title"
          )}
          fontSize={"16px"}
          fontWeight={"normal"}
          textAlign={"center"}
        >
          {heading}
        </Text>
      ) : null}
        <Box className={cx("notification-modal_body ", classNames?.body)}
          mt={"3"}
          fontSize={"sm"}
        >
          <Formik 
            onSubmit={onConfirm}
            initialValues={initialValue}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            <Form >
            {body ? <>{body}</>: null}
            {props.children}
            </Form>
            </Formik>
        </Box>
    </div>
  )
}

function ModalFooter() {
  const {
    confirmText,
    cancelText,
    closeText,
    onConfirm,
    onCancel,
    onClose,
    classNames,
    showConfirmCTA,
    showCancelCTA,
    showCloseCTA,
  } = useNotificationModalContext()

  return (
    <Box className={cx("notification-modal_footer", classNames?.footer)}
      display={"flex"}
      justifyContent={"center"}
      py={4}
    >
      {showConfirmCTA ? (
        <>
          {showCancelCTA ? (
            <Button
              onClick={onCancel}
              color="#20476e"
              className={cx("rounded-10", showCancelCTA && "mr-1")}
              mr={8}
              border="1px solid #20476e"
              borderRadius="4"
              width={"6rem"}
            >
              {cancelText}
            </Button>
          ) : null}

          <Button
           type="submit"
            // onClick={onConfirm}
            className={cx("rounded-10", showCancelCTA && "mr-2")}
            width={"6rem"}
            p={4}
            color="white"
            borderRadius="4"
            cursor="pointer"
            variant={"filled"}
            backgroundColor={"#20476e"}
          >
            {confirmText}
          </Button>
        </>
      ) : null}

      {showCloseCTA ? (
        <Button onClick={onClose} color="#20476e" border="1px solid #20476e"
            p={5}
            width={"6rem"}
            borderRadius="4" className="rounded-10">
          {closeText}
        </Button>
      ) : null}
    </Box>
  )
}

Modal.Body = ModalBody
Modal.Footer = ModalFooter

export default Modal
