import React, { createContext, useContext, useState } from 'react';
import User from "../helpers/current-user";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(User.getRole());
  const [authenticated, setAuthenticated] = useState(User.loggedin());

  return (
    <UserContext.Provider value={{ userRole, setUserRole, authenticated, setAuthenticated }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
