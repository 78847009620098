import { extendTheme } from "@chakra-ui/react";
import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const filled = defineStyle({
  cursor: "pointer",
  color: "white",
  _hover: {
    opacity: 0.9,
  },
  _active: {
    opacity: 0.6,
    transform: "scale(0.98)",
  },
});

const link = defineStyle({
  cursor: "pointer",
  _hover: {
    opacity: 0.9,
  },
  _active: {
    opacity: 0.6,
    transform: "scale(0.98)",
  },
});
export const buttonTheme = defineStyleConfig({
  variants: { filled, link },
});

const theme = extendTheme({
  components: { Button: buttonTheme },
  colors: {
    primary_theme: "#20476e",
    secondary_theme: "#2F68A1",
    light_blue: "#D3E4F4",
    theme_black: "#000000cc",
    theme_white: "rgb(255,255,255)"
  },
  fonts: {
    pri: "Open Sans",
    sec: "Roboto",
  },
});
export const customColors = {
  blue: {
    50: "#E0E8F1",
    100: "#D3E4F4",
    200: "#869BBE",
    300: "#598DAB",
    400: "#407BA2",
    500: "#306B97",
    600: "#2F68A1",
    700: "#234E74",
    800: "#1D405E",
    900: "#20476e",
  },
	lightBlue: {
		100: "#f5f9ff",
		200: "#c7e6ef",
		300: "#EDF2F7"
	},
	gray: {
		50: "#F7FAFC",
		100: "#F3F3F6",
		200: "#11253F33",
		300: "#11253F1A",
		400: "#A0AEC0",
		500: "#6A6A86",
		600: "#4A5568",
		700: "#2D3748",
		800: "#181824",
		900: "#171923",
		1000: "#231752"
	},
	green: {
		50: "#F0FFF4",
		100: "#1FAC8C",
		200: "#9AE6B4",
		300: "#68D391",
		400: "#48BB78",
		500: "#38A169",
		600: "#2F855A",
		700: "#276749",
		800: "#22543D",
		900: "#1C4532"
	},
	red: { 
		100: "#ff6666", 
		200: "#ff4c4c",
		300: "#ff3232",
		400: "#ff1919",
		500: "#FF0000", 
	},
	yellow: {
		100: "rgba(233,180,48,0.45)",
		200: "rgba(233,180,48,0.55)",
		300: "rgba(233,180,48,0.65)",
		400: "rgba(233,180,48,0.75)",
		500: "rgb(233,180,48)",
	},
	white: { 
		100: "rgba(255,255,255,0.25)",
		200: "rgba(255,255,255,0.35)",
		300: "rgba(255,255,255,0.45)",
		400: "rgba(255,255,255,0.55)",
		500: "rgba(255,255,255,0.65)",
		600: "rgba(255,255,255,0.75)",
		700: "rgba(255,255,255,0.85)",
		800: "rgba(255,255,255,0.95)",
		900: "rgb(255,255,255)"
	}, 
	black: {
		100: "#000000cc"
	},
	transparent: {
		100: "00FFFFFF"
	},
	pdfTheme: {
		100: "#f2f4fB",
		200: "#222",
		
	}
};  

export default theme;
