import DgsButton from "../dgs-button";
import PropTypes from "prop-types";

//__TODO__: style the error component based on the design
export default function ErrorBoundaryFallback({ error, resetErrorBoundary }) {
  const handleClick = () => { 
    resetErrorBoundary ? resetErrorBoundary() : window.location.reload();
  };
  return (
    <div>
      <div>
        Technical Error:{" "}
        <DgsButton color="primary" variant="link" onClick={handleClick} className="px-2">
          Try again
        </DgsButton>
      </div>
      <pre className="text-red-500 bg-red-50 p-4">{error.message}</pre>
      <div className="my-2">
        Please try again later 
      </div>
    </div>
  );
}

ErrorBoundaryFallback.defaultProps = {
  error: {},
};

ErrorBoundaryFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  resetErrorBoundary: PropTypes.func,
};
