import {
  AUTHENTICATED_PAGE_NAMES,
  authenticatedRoutes,
} from "../routes/Authenticated";
import AuditVisitIcon from "../assets/auditor-list-light";
import ManageAuditorsIcon from "../assets/manage-auditor-light";
import MyAuditsListing from "../assets/audit-listing-light";
import SelectedMyAuditsListing from "../assets/audit-listing-dark";
import SelectedAuditVisitIcon from "../assets/auditor-list-dark";
import SelectedManageAuditorsIcon from "../assets/manage-auditor-dark";
import Const from "../helpers/constants";
import User from "../helpers/current-user";
import is from "../utils/is";
import { generatePath } from "react-router";

const userRoles = User?.get().role;

const checkHasAcess=(menuItem)=>{
if (is.array(userRoles)) {
 return menuItem?.access?.some(element=> userRoles.includes(element)); 
} else {
  return menuItem.access?.includes(userRoles)
}
}

function getNcFindingPath(){
  const userInfo= User?.get()
  if(userInfo.is_ro || userInfo.is_auditor){
    return generatePath(authenticatedRoutes?.find(
      (route) =>
        route.pageName === (User?.get()?.is_ro ? AUTHENTICATED_PAGE_NAMES.AUDITOR_NC_FINDING : AUTHENTICATED_PAGE_NAMES.AUDITOR_NC_AUDITS)
    )?.path, {
      ro_id: User?.get()?.is_ro && User?.get()?.id
    })
  }else{
    return ""
  }
  
}

function SidemenuRoutes() {
  const adminSideMenu = [
    {
      id: 1,
      name: "RO Audits",
      icon: AuditVisitIcon,
      selected_icon: SelectedAuditVisitIcon,
      access: [Const.Role.COMPETENT_AUTHORITY],
      to: authenticatedRoutes?.find(
        (route) =>
          route.pageName === AUTHENTICATED_PAGE_NAMES.RO_AUDIT
      )?.path,
    },
    {
      id: 1444,
      name: "Checklists",
      icon: AuditVisitIcon,
      selected_icon: SelectedAuditVisitIcon,
      access: [Const.Role.RO],
      to: authenticatedRoutes?.find(
        (route) =>
          route.pageName === AUTHENTICATED_PAGE_NAMES.RO_FORM_CHECKLIST
      )?.path,
    },
    {
      id: 2,
      name: "Manage Users",
      icon: ManageAuditorsIcon,
      selected_icon: SelectedManageAuditorsIcon,
      access: [Const.Role.ADMIN],
      to: authenticatedRoutes?.find(
        (route) =>
          route.pageName ===
          AUTHENTICATED_PAGE_NAMES.MANAGE_USERS
      )?.path,
    },
    {
      id: 3,
      name: "Manage RO",
      icon: AuditVisitIcon,
      selected_icon: SelectedAuditVisitIcon,
      access: [Const.Role.ADMIN],
      to: authenticatedRoutes?.find(
        (route) =>
          route.pageName === AUTHENTICATED_PAGE_NAMES.MANAGE_RO
      )?.path,
    },
    {
      id: 4,
      name: "My Audits",
      icon: AuditVisitIcon,
      selected_icon: SelectedAuditVisitIcon,
      access: [Const.Role.AUDITOR],
      to: authenticatedRoutes?.find(
        (route) =>
          route.pageName === AUTHENTICATED_PAGE_NAMES.AUDITOR_AUDITS
      )?.path,
    },
    {
      id: 5,
      name: User?.get()?.is_auditor ? "View Findings Reports" : "Audit Report",
      icon: AuditVisitIcon,
      selected_icon: SelectedAuditVisitIcon,
      access: [Const.Role.AUDITOR, Const.Role.RO],
      to: getNcFindingPath(),
    },
    {
      id: 6,
      name: "Summary of Findings",
      icon: MyAuditsListing,
      selected_icon: SelectedMyAuditsListing,
      access: [Const.Role.COMPETENT_AUTHORITY,],
      to: authenticatedRoutes?.find(
        (route) =>
          route.pageName === AUTHENTICATED_PAGE_NAMES.FINDINGS_REPORT_LIST
      )?.path,
    },
    {
      id: 7,
      name: "CA/Auditor Documents",
      icon: MyAuditsListing,
      selected_icon: SelectedMyAuditsListing,
      access: [Const.Role.AUDITOR, Const.Role.COMPETENT_AUTHORITY, Const.Role.ADMIN],
      to: authenticatedRoutes?.find(
        (route) =>
          route.pageName === AUTHENTICATED_PAGE_NAMES.CA_AUDITOR_DOCUMENT
      )?.path,
    },
    {
      id: 8,
      name: "DG Instructions",
      icon: MyAuditsListing,
      selected_icon: SelectedMyAuditsListing,
      access: [Const.Role.AUDITOR, Const.Role.RO, Const.Role.COMPETENT_AUTHORITY, Const.Role.ADMIN],
      to: authenticatedRoutes?.find(
        (route) =>
          route.pageName === AUTHENTICATED_PAGE_NAMES.UPLOAD_DOCUMENT
      )?.path,
    },
  ];

  return [...adminSideMenu,].filter((menuItem)=> checkHasAcess(menuItem));
}

export default SidemenuRoutes;
