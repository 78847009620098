import * as React from "react";
const AuditorList = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_905_58)">
      <g clip-path="url(#clip1_905_58)">
        <path
          d="M2.39996 2.25456V6.82405C2.39996 6.96724 2.42111 7.10965 2.46272 7.24667L2.48663 7.32544C2.67275 7.93834 3.22974 8.28318 3.8536 8.13798C4.71992 7.93635 5.75365 7.84557 6.23996 9.20002C6.64015 10.3146 7.02236 10.9339 7.43836 11.1407C7.54105 11.1917 7.6579 11.2 7.77258 11.2H13.7454C14.5487 11.2 15.2 10.5488 15.2 9.74547V2.25456C15.2 1.45124 14.5487 0.800018 13.7454 0.800018H3.85451C3.05119 0.800018 2.39996 1.45124 2.39996 2.25456Z"
          fill="white"
          stroke="#11253F"
          stroke-width="0.872727"
        />
        <path
          d="M5.39044 3.4281H9.39044"
          stroke="#11253F"
          stroke-width="0.872727"
          stroke-linecap="round"
        />
        <path
          d="M5.39044 6.12207H9.39044"
          stroke="#11253F"
          stroke-width="0.872727"
          stroke-linecap="round"
        />
        <path
          d="M8.05713 8.81613H9.65713"
          stroke="#11253F"
          stroke-width="0.872727"
          stroke-linecap="round"
        />
        <path
          d="M11.2571 3.36071L11.7904 3.96687L12.8571 3.15866"
          stroke="#11253F"
          stroke-width="0.727273"
          stroke-linecap="round"
        />
        <path
          d="M11.2571 5.78533L11.7904 6.39149L12.8571 5.58328"
          stroke="#11253F"
          stroke-width="0.727273"
          stroke-linecap="round"
        />
        <path
          d="M11.2571 8.20999L11.7904 8.81614L12.8571 8.00793"
          stroke="#11253F"
          stroke-width="0.727273"
          stroke-linecap="round"
        />
        <circle
          cx="5.45457"
          cy="9.09089"
          r="2.18182"
          fill="white"
          stroke="#11253F"
          stroke-width="0.727273"
        />
        <path
          d="M5.45457 11.6364C2.14548 11.6364 0.924265 14.0606 0.727295 15.2727H10.1818C9.98487 14.0606 8.76366 11.6364 5.45457 11.6364Z"
          fill="white"
          stroke="#11253F"
          stroke-width="0.872727"
          stroke-linecap="square"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_905_58">
        <rect width="16" height="16" fill="white" />
      </clipPath>
      <clipPath id="clip1_905_58">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default AuditorList;
