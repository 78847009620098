import React from "react"
import Const from "../../helpers/constants"
import { useMemo } from "react"
import IconSuccess from "../../assets/icon-success";
import IconWarning from "../../assets/icon-warning";
import InfoIcon from "../../assets/info-icon";
import IconError from "../../assets/icon-error";
import IconLoading from "../../assets/icon-loading"

function NotificationIcon(props) {
  const { type } = props

  const icon = useMemo(() => {
    const style = {
      width: "24px",
      height: "24px",
      color: "#34c38f",
      fontWeight: "bold",
      fontSize: "44px",
    }

    switch (type) {
      case Const.NOTIFICATION_TYPE.SUCCESS:
        return   <IconSuccess />
      case Const.NOTIFICATION_TYPE.ERROR:
        return (
          <IconError/>
        )
      case Const.NOTIFICATION_TYPE.INFO:
        return (
          <InfoIcon />
        )
      case Const.NOTIFICATION_TYPE.PROGRESS:
        return (
          <div role="status" className="ms-2 spinner-border text-primary">
            <IconLoading/>
          </div>
        )

      case Const.NOTIFICATION_TYPE.WARNING:
        return  <IconWarning />
      default:
        return null
    }
  }, [type])

  return <div className="notification-modal_icon ">{icon}</div>
}

export default NotificationIcon
