const Const = {
  Role: {
    ADMIN: "ADMIN",
    AUDITOR: "AUDITOR",
    PORT: "PORT",
    MMD_SUPERVISOR:"MMD_SUPERVISOR"
  },
  LIST_LIMIT: 20
};
export default Const;
