export const MESSAGES = {
  SYSTEM_FAILURE: "Something went wrong. Please contact the Administrator.",
  NETWORK_ISSUE: "Please check your network connection.",
  ARE_YOU_SURE: "Are You Sure?",

  DELETE_USER_CONFIRMATION: "The user will be deleted and cannot be retrieved.",
  DELETE_FINDING_CONFIRMATION: "The finding will be deleted and cannot be retrieved.",
  SUBMIT_FORM_CONFIRMATION:
    "The form will be submitted, and you won't be able to edit the details.",
  SUBMIT_CA_REQUEST_CONFIRMATION:
    "Do you want to submit the request to Competent Authority?",
    AUDIT_COMPLETE_CONFIRMATION:
    "Do you want to mark audit as complete?",
  DELETE_USER_PROGRESS: "Deleting user", 
  DELETE_USER_SUCCESS: "User deleted",
  DELETE_USER_FAILED: "Failed to delete user",

  UPLOAD_DOC_PROGRESS: "Uploading document",
  UPLOAD_DOC_SUCCESS: "Document uploaded",
  UPLOAD_DOC_FAILED: "Document uploading failed",

  PREVIEW_SUCCESS: "Preview success",
  PREVIEW_PROGRESS: "Generating preview",
  PREVIEW_FAILED: "Failed to preview",
  
  DELETE_DOC_CONFIRMATION:
    "The document will be deleted and cannot be retrieved",
  DELETE_DOC_PROGRESS: "Deleting document",
  DELETE_DOC_SUCCESS: "Document deleted",
  DELETE_DOC_FAILED: "Failed to delete document",

  SAVE_DOC_PROGRESS: "Adding document",
  SAVE_DOC_SUCCESS: "Document added",
  SAVE_DOC_FAILED: "Failed to save document",

  SAVE_USER_PROGRESS: "Adding user",
  SAVE_FORM_PROGRESS: "Saving in progress",
  SAVE_USER_SUCCESS: "User added.",
  DOWNLOAD_REPORT_PROGRESS: "Your report is being generated, please wait...",
  DOWNLOAD_FAILED: {
    undefined: "Download document failed",
    errorMesagge: "Download document failed",
  },
  CA_REVIEW_CONFIRMATION:
  "Audit will be marked as reviewed",
  SAVE_USER: {
    undefined: "Failed to save User",
    errorMesagge: "Failed to save User",
    200: "The user has been successfully added",
    400: "We couldn't add the user. Please try again.",
    401: "Your login credentials are invalid. Please check and try again.",
    409: "A user with the same details already exists",
  },
  UPDATE_USER: {
    200: "The user has been successfully updated",
    400: "We were unable to update the user. Please try again.",
    401: "Your login credentials are invalid. Please check and try again.",
    409: "A user with the same details already exists",
    undefined: "Failed to update User",
    errorMesagge: "Failed to update User",
  },
  SAVE_RO: {
    undefined: "Failed to save RO ",
    errorMesagge: "Failed to RO ",
    200: "The RO has been successfully added",
    400: "We couldn't add the RO. Please try again.",
    401: "Your login credentials are invalid. Please check and try again.",
    409: "A RO with the same details already exists",
  },
  UPDATE_RO: {
    200: "The RO has been successfully updated",
    400: "We were unable to update the RO. Please try again.",
    401: "Your login credentials are invalid. Please check and try again.",
    409: "A RO with the same details already exists",
    undefined: "Failed to update RO",
    errorMesagge: "Failed to update RO",
  },
  SAVE_FORM: {
    200: "Form is successfully saved",
    400: "We were unable to save the form. Please try again.",
    409: "A form with the same details already exists.",
    undefined: "Failed to save form",
    errorMesagge: "Failed to save form",
  },
  SUBMIT_FORM: {
    200: "Form details are successfully submitted",
    400: "We were unable to submit the form. Please try again.",
    409: "A form with the same details already exists",
    undefined: "Failed to submit form",
    errorMesagge: "Failed to submit form",
  },
  AUDIT_REQUEST: {
    undefined: "Failed to send request",
    errorMesagge: "Failed to send request",
    200: "The reports were sent to the Competent Authority",
    403: "Not all forms have been completed.",
  },
  AUDIT_COMPLETE: {
    undefined: "Failed to mark complete",
    errorMesagge: "Failed to send request",
    200: "The audit has been completed",
    403: "Not all forms have been completed.",
  },
  UPLOAD_DOCUMENTS_PROGRESS:"UPLOAD_DOCUMENTS",
  UPLOAD_DOCUMENTS: {
    undefined: "Failed to upload document",
    errorMesagge: "Failed to upload document",
    200: "The document has been successfully uploaded",  
    409: "A document with the same name already exists",
  },
  DELETE_DOCUMENT_CONFIRMATION: "The document will be deleted and cannot be retrieved",
  DELETE_DOCUMENT_PROGRESS: "Deleting document",
  DELETE_DOCUMENT: {
    200: "The document has been deleted.",  
    undefined: "Failed to delete document",
    errorMesagge: "Failed to delete document",
  },
  ACCESS_DENIED: {
    TITLE: "Access is Denied",
    HEADING: (
      <>
        You do not have permission to access this resource. Please check with{" "}
        <span className="uppercase">RO Audits</span> Support Team.
      </>
    ),
  },

  TECHNICAL_ERROR: {
    TITLE: "Technical Error",
    HEADING: (
      <>
        Please try again later or check with{" "}
        <span className="uppercase">RO Audits</span> Support Team.
      </>
    ),
  },
  SESSION_EXPIRED: {
    TITLE: "Session Expired",
    HEADING: "You will be redirected to the Login page",
  },
};

export const updatePasswordMessages = {
  0: "You are required to change your password",
  204: "Your password is changed successfully",
  400: "Please enter the correct old password",
  undefined: "Failed to change password, please retry",
};
export const forgotPasswordMessages = {
  204: "We've received your request. Please check your email for a temporary password",
  404: "Please check your email and try again",
  undefined: "Failed to reset password, please retry",
};
export const authMessages = {
  200: "Logged in",
  401: "Invalid credentials. Please try again.",
  general: "Failed to Login. Please try again",
};
export const listMessages = {
  400: "Could not fetch records. Please try again.",
  401: "Invalid user credentials. Please try again.",
  403: "User is unauthorized to access this page.",
  404: "Not found",
  409: "PRF pending for previous open request",
  412: "Could not plan audit until. PRF is pending",
};
