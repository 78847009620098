import React from "react";

const DGSPermittedSection = (props) => {
  const { children, loggedInRole = "", role = [] } = props;
  return (
    <React.Fragment>
      {/* TODO: ADD ROLES BASED LINKS */}
      {role.some((r) => r === loggedInRole) ? children : children}
    </React.Fragment>
  );
};

export default DGSPermittedSection;
