import React from "react";
import { Center, Box, Text, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function index() {
  return (
    <Center fontFamily={"pri"} h="78vh">
      <Box textAlign="center" overflow={"hidden"}>
        <Text fontSize="6xl" fontWeight="bold">
          404
        </Text>
        <Text fontSize="2xl" mt={4}>
          Page not found
        </Text>
        <Text fontSize="lg" mt={4}>
          The page you are looking for does not exist.
        </Text>
        <Button as={Link} to="/" mt={6} variant={"filled"} bg="blue">
          Go Home
        </Button>
      </Box>
    </Center>
  );
}
